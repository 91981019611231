import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RenderTodoList from '../../components/rendertodolist/RenderTodoList'

const HomePage = () => {
    const [arrTodos, setarrTodos] = useState([]);
    const [strTodo, setstrTodo] = useState('')

    const inputHandler = (event) => {
        setstrTodo(event.target.value)
    }

    const addTodo = () => {
        const objTodo = {
            id:uuidv4(),
            title:strTodo,
            completed: false,
            deleted: false,
        }
        const arrTemp=arrTodos
        arrTemp.push(objTodo)
        setarrTodos(arrTemp)
        setstrTodo('')
    }

const deleteTodo=(i)=>{
    console.log('i',i)
    const objTemp = arrTodos[i];
    objTemp.deleted = true;
    setarrTodos(prev => [...prev.slice(0, i), objTemp, ...prev.slice(i + 1)]);



    // const arrTemp=arrTodos
    // arrTemp[i].deleted=true
    // setarrTodos(arrTemp)
}

    return (
        <div>
            <TextField
                required
                id="outlined-required"
                label="Required"
                onChange={(event) => { inputHandler(event) }}
                value={strTodo}
            />
            <Button variant="outlined"
                onClick={() => { addTodo() }}
            >Add to list</Button>
            <RenderTodoList 
            arrTodos={arrTodos}
            deleteTodo={(i)=>{deleteTodo(i)}}
            />
        </div>
    )
}

export default HomePage
