import HomePage from '../src/Pages/homepage/HomePage'
import './App.css';

function App() {
  return (
    <div className="App">
<HomePage />
    </div>
  );
}

export default App;
