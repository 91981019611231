import React from 'react';
import Button from '@mui/material/Button';

const RenderTodoList = ({ arrTodos, deleteTodo }) => {
    console.log('arrTodos', arrTodos)
    return (
        arrTodos !== [] && arrTodos.map((objTodo, i) => (
            objTodo.deleted === false &&<div key={objTodo.id}>
                {objTodo.title}
                <Button variant="outlined"
                onClick={()=>{deleteTodo(i)}}
                >Delete</Button>
            </div>
        )
        )
    )
}

export default RenderTodoList;